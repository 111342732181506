import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"

const Link = styled(GatsbyLink)`
  color: ${props => props.theme.colors.main};

  &:hover {
    color: ${props => props.theme.colors.hover};
  }
`

export default Link
