import React from "react"

//Styled components imports
import Wrapper from "./Wrapper"
import ErrorCode from "./ErrorCode"
import ErrorText from "./ErrorText"
import LinkText from "./LinkText"
import Link from "./Link"

const NotFound = () => {
  return (
    <Wrapper>
      <ErrorCode>404</ErrorCode>
      <ErrorText>Uh, tady nic není...</ErrorText>
      <LinkText>
        Přejít na <Link to="/">úvodní stránku</Link>.
      </LinkText>
    </Wrapper>
  )
}

export default NotFound
