import React from "react"

//React components imports
import Layout from "../components/Layout"
import NotFound from "../components/NotFound"

const NotFoundPage = () => {
  return (
    <Layout location="404">
      <NotFound />
    </Layout>
  )
}

export default NotFoundPage
