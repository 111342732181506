import styled from "styled-components"

const Wrapper = styled.div`
  max-width: 720px;
  margin: auto;
  text-align: center;
  padding-top: 64px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    padding-top: 72px;
  }
`

export default Wrapper
